import { createSlice } from '@reduxjs/toolkit';

const cookieConsentSlice = createSlice({
    name: "cookieConsent",
    initialState: {
        consentGranted: undefined
    },
    reducers: {
      setConsentGranted: (state, action) => {
          return {
            ...state,
            consentGranted: action.payload
          }
      }
    },
});

export const { setConsentGranted } = cookieConsentSlice.actions;
export default cookieConsentSlice.reducer;
