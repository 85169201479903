import PropTypes from "prop-types";
import { Children } from 'react';

const Modal = (inputProps) => {
  const props = {
    titleIcon: '',
    verticalAlign: 'bottom',
    horizontalAlign: 'center',
    header: 'Terms and Services',
    subHeading: '',
    content: '',
    children: null,
    declineText: 'Decline',
    acceptText: 'Accept',
    onAccept: () => {},
    onDecline: () => {},
    onDismiss: () => {},
    ...inputProps
  }

  return <div className={`modal modal-${props.verticalAlign}`} style={{
    display: 'flex'
  }}>
	<article className={`modal-container modal-${props.horizontalAlign}`}>
		<header className="modal-container-header">
      <div style={{display: 'flex', alignItems: 'center'}}>
        <i className={`fa ${props.titleIcon}`}></i>
        <h2 className="modal-container-title">
          {props.header}
        </h2>
      </div>
			<button className="icon-button" onClick={() => props.onDismiss()}>
        <i className="fa fa-times"></i>
			</button>
		</header>
		<section className="modal-container-body">
			<h2>{props.subHeading}</h2>
			<span>{props.content}</span>
      {props.children}
		</section>
		<footer className="modal-container-footer">
			<button className="button btn-hover rounded is-ghost" onClick={() => props.onDecline()}>
        <a href="/" className="rounded">{props.declineText}</a>
      </button>
			<button className="button btn-hover rounded" onClick={() => props.onAccept()}>
        <a href="/" className="rounded">{props.acceptText}</a>
      </button>
		</footer>
	</article>
</div>
}

Modal.propTypes = {
  verticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
  horizontalAlign: PropTypes.oneOf(['left', 'center', 'right']),
  header: PropTypes.string,
  subHeader: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.instanceOf(Children),
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  onDismiss: PropTypes.func,
  declineText: PropTypes.func,
  acceptText: PropTypes.func
};

export default Modal