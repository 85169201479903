import { createSlice } from '@reduxjs/toolkit';

const authorizerSlice = createSlice({
    name: "authorizer",
    initialState: {
        accessToken: ""
    },
    reducers: {
      setGuestToken: (state, action) => {
          return {
            ...state,
            accessToken: action.payload
          }
      }
    },
});

export const { setGuestToken } = authorizerSlice.actions;
export default authorizerSlice.reducer;
