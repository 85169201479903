import { connect } from 'react-redux'
import axios from 'axios'
import { setGuestToken } from '../../store/slices/authorizer-slice'
import { base64Decode } from '../../helpers/base64'
import { useEffect } from 'react'


const Authorizer = ({ accessToken, setGuestToken }) => {
  useEffect(() => {
    if (typeof accessToken === 'string') {
      const payload = accessToken.split('.')[1]
      if (payload) {
        const { exp } = JSON.parse(base64Decode(payload))
        const now = Math.floor(new Date(new Date().toUTCString()).getTime() / 1000)
        if (now < (exp - 300)) {
          return
        }
      }
    }
    axios.get(process.env.REACT_APP_API_URL + '/v1/tokens/guest')
      .then((res) => setGuestToken(res?.data?.token))
      .catch((err) => {
        console.log(err)
      })
  }, [accessToken, setGuestToken])
}

const mapStateToProps = (state) => ({accessToken: state.authorizer.accessToken})

const mapDispatchToProps = {
  setGuestToken
}

export default connect(mapStateToProps, mapDispatchToProps)(Authorizer)