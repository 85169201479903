import ReactGA from 'react-ga4'
import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Modal from "./components/modal/Modal"
import { connect } from 'react-redux'
import { setConsentGranted } from './store/slices/cookie-consent-slice'

// home pages
const Home = lazy(() => import("./pages/home/Home"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const AgfSewcialite = lazy(() => import('./pages/agf-sewcialite/AgfSewcialite'))

const App = (props) => {

  if (props.consentGranted && process.env.REACT_APP_GA_MEASUREMENT_ID) {
    console.log('Initializing Google Analytics')
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID)
  }

  return (
      <Router>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className="flone-preloader-wrapper">
                <div className="flone-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            {props.consentGranted === undefined ? 
          <Modal 
            titleIcon="fa-cookie-bite"
            verticalAlign="bottom"
            horizontalAlign="right"
            header="We value your privacy"
            content={`We use cookies to enhance your browsing experience and analyze our traffic. By clicking "Accept", you consent to their use.`}
            onAccept={() => {
              props.setConsentGranted(true)
            }}
            onDismiss={() => {
              props.setConsentGranted(false)
            }}
            onDecline={() => {
              props.setConsentGranted(false)
            }}
          /> : null
          }
            <Routes>
              <Route
                path={process.env.PUBLIC_URL + "/"}
                element={<Home/>}
              />

              {/* Homepages */}
              <Route
                path={process.env.PUBLIC_URL + "/home"}
                element={<Home/>}
              />

              {/* Other pages */}
              <Route
                path={process.env.PUBLIC_URL + "/about"}
                element={<About/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/contact"}
                element={<Contact/>}
              />

              <Route
                path={process.env.PUBLIC_URL + "/agf-sewcialite"}
                element={<AgfSewcialite/>}
              />

              <Route path="*" element={<NotFound/>} />
            </Routes>
          </Suspense>
        </ScrollToTop>
      </Router>
  );
};

const mapStateToProps = (state) => ({consentGranted: state.cookieConsent.consentGranted})

const mapDispatchToProps = {
  setConsentGranted
}

export default connect(mapStateToProps, mapDispatchToProps)(App)